import React from "react";

const useEscapeHtml = (data) => {
  const map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    "&#039;": "'"
  };
  return data.replace(new RegExp(Object.keys(map).join('|'), 'g'), function(m) { return map[m]; });
}

export default useEscapeHtml;
