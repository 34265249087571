// Libs
import React from 'react';
import {Link} from 'gatsby';
// Assets.
import styles from './new-members.module.scss';
import pageAliases from '../../data/pageAliases';
import {newMembers} from '../../locale/locale.json';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";
import {get} from "lodash";
import useEscapeHtml from "../../hooks/useEscapeHtml";

const NewMembers = () => {

  const labels = usePagesRegistration()
  const loginPageRegisterTitle = get(labels, 'configPagesRegistration.loginPageRegisterTitle', newMembers.title);
  const loginPageRegisterDescri = get(labels, 'configPagesRegistration.loginPageRegisterDescri', null);
  const registerDescription = useEscapeHtml(loginPageRegisterDescri);

  return (
    <div className={styles.newMembersContainer}>
      <h2>{loginPageRegisterTitle}</h2>

      {registerDescription ? <>
          <div dangerouslySetInnerHTML={{__html: registerDescription}}/>
        <Link className={styles.newMembersButton} to={pageAliases.register}>
          {newMembers.cta}
        </Link>
          </> :
        <><p>{newMembers.subtitle}</p>
          <ul>
            <li>{newMembers.benefitsPoints.line1}</li>
            <li>{newMembers.benefitsPoints.line2}</li>
            <li>{newMembers.benefitsPoints.line3}</li>
            <li>{newMembers.benefitsPoints.line4}</li>
          </ul>
          <Link className={styles.newMembersLink} to={pageAliases.earnRewards}>
            {newMembers.learnMore}
          </Link>
          <Link className={styles.newMembersButton} to={pageAliases.register}>
            {newMembers.cta}
          </Link></>}
    </div>
  );
};

export default NewMembers;
