// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import UserLogin from '../components/user/user-login';

const UserLoginPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="User Login" />
      <UserLogin search={location.search} />
    </Layout>
  );
};

export default UserLoginPage;
