// Libs.
import React, {useState, useEffect, useContext} from 'react';
import Img from 'gatsby-image';
import {get, isEmpty} from 'lodash';
// Deps.
import NewMembers from './new-members';
import GuestMembers from './guest-members';
import UserLoginForm from './user-login-form';
// Assets.
import styles from './user-login.module.scss';
import locale from '../../locale/locale';
// Hooks.
import {useImageStaticQuery} from '../../hooks/useImagesStaticQuery';
import guestDestination from '../../libs/guestSignin';
import {useStateValue} from '../../store/state';
import PagePreloader from '../common/loaders/page-preloader';
import pageAliases from '../../data/pageAliases';
import qs from 'querystringify';
import {AppContext} from '../layout/AppLayout';
import navigate from '../../libs/navigate';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";

const UserLogin = ({search = ''}) => {
  const {guestMode, destination} = guestDestination();
  const [state] = useStateValue();
  const [submission, setSubmission] = useState(true);
  const app = useContext(AppContext);
  let imgPlaceholderLoginXl;
  let imgPlaceholderLoginLg;
  let imgPlaceholderLoginMd;


  const labels = usePagesRegistration()

  const loginTitle = get(labels, 'configPagesRegistration.loginTitle', 'Already a member?');
  const loginDescription = get(labels, 'configPagesRegistration.loginDescription', locale.account.signInNotice);

  useEffect(() => {
    const {ReturnURL = '', orderType = '', orderId = ''} = qs.parse(search || '') || {};
    // console.log('ReturnUrl', {
    //   search,
    //   ReturnURL,
    //   orderId,
    //   orderType,
    // });

    // Handle a legacy URL path for sending users to Earn Rewards vs the Login page.
    if (ReturnURL === '/thank-you-rewards/redeem-rewards/') {
      setTimeout(() => navigate(pageAliases.earnRewards), 50);
    } else if (orderType === 'classes') {
      if (parseInt(orderId)) {
        if (!isEmpty(state.user.access_token)) {
          navigate(`/account-order-history/detail/${orderId}`);
        } else {
          navigate(
            `${pageAliases.login}?destination=/account-order-history/detail/${orderId}`,
          );
        }
      } else {
        navigate(`/404/`);
      }
    } else if (orderType === 'fmk') {
      if (!isEmpty(orderId)) {
        if (!isEmpty(state.user.access_token)) {
          navigate(`${pageAliases.festiveMealKitsOrderDetails}${orderId}`);
        } else {
          navigate(`${pageAliases.login}?destination=${pageAliases.festiveMealKitsOrderDetails}${orderId}`);
        }
      } else {
        navigate(`/404/`);
      }
    }

    setSubmission(false);
  }, []);

  // Hooks must stay on root, non-conditionally
  const staticImages = {
    bread: {
      xl: useImageStaticQuery('login/user-login-bread.png'),
      lg: useImageStaticQuery('login/user-login-bread.png'),
      md: useImageStaticQuery('login/user-login-bread.png'),
    },
    okra: {
      xl: useImageStaticQuery('login/guest-login-okra.png'),
      lg: useImageStaticQuery('login/guest-login-okra.png'),
      md: useImageStaticQuery('login/guest-login-okra.png'),
    },
  };

  if (!guestMode) {
    imgPlaceholderLoginXl = staticImages.bread.xl;
    imgPlaceholderLoginLg = staticImages.bread.lg;
    imgPlaceholderLoginMd = staticImages.bread.md;
  } else {
    imgPlaceholderLoginXl = staticImages.okra.xl;
    imgPlaceholderLoginLg = staticImages.okra.lg;
    imgPlaceholderLoginMd = staticImages.okra.md;
  }

  return (
    <>
      {submission ? (
        <PagePreloader/>
      ) : (
        <div className={styles.userLoginBackground} data-guest={guestMode} data-app={!!app}>
          <div className={styles.userLoginImageLg}>
            <Img fluid={imgPlaceholderLoginLg.childImageSharp.fluid}/>
          </div>
          <div className={styles.userLoginImageMd}>
            <Img fluid={imgPlaceholderLoginMd.childImageSharp.fluid}/>
          </div>
          <div className={styles.userLoginContainer}>
            <UserLoginForm
              title={loginTitle}
              summary={guestMode ? locale.account.signInNoticeGuest : loginDescription}
            />
            {guestMode ? <GuestMembers destination={destination}/> : <NewMembers/>}
            <div className={styles.userLoginImageXl}>
              <Img fluid={imgPlaceholderLoginXl.childImageSharp.fluid}/>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserLogin;
