// Libs
import React from 'react';
// State
import { useStateValue } from '../../store/state';
import { GuestCheckout } from '../../store/checkout/action-creators';
// Assets.
import styles from './guest-members.module.scss';
import pageAliases from '../../data/pageAliases';
import navigate from '../../libs/navigate';

const GuestMembers = ({ destination }) => {
  const [, dispatch] = useStateValue();
  const startGuestCheckout = () => {
    dispatch(GuestCheckout());
    // Slight delay to ensure state has taken effect... else the user might be sent back to the guest/login again.
    setTimeout(() => navigate(!!destination && destination !== '' ? destination : pageAliases.classesCheckout), 100);
  };

  return (
    <div className={styles.guestMembersContainer}>
      <h2>Guest Checkout</h2>
      <p>Not a Thank You Rewards Member? Proceed as a guest.</p>
      <button className={styles.guestMembersButton} onClick={startGuestCheckout}>
        Continue As Guest
      </button>
    </div>
  );
};

export default GuestMembers;
